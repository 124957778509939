import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styles from './button.module.scss'

const Button = props => {
  const { to, className } = props
  const buttonClassName = [styles.button, className].join(' ')

  return (
    <Link className={buttonClassName} to={to} {...props}>
      <span className={styles.buttonText}>{props.children}</span>
    </Link>
  )
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline']),
}

export default Button
