import React from 'react'
import Layout from '../components/Layout'
import Button from '../components/Button'

const handleBackClick = event => {
  event.preventDefault()
  window.history.back()
}

const style = {
  wrapper: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 100px)',
  },
  header: {
    textTransform: 'uppercase',
    marginBottom: '50px',
  },
}

const NotFoundPage = () => (
  <Layout>
    <div style={style.wrapper}>
      <div>
        <h1 style={style.header}>Page not Found</h1>
        <Button onClick={handleBackClick} style={style.button}>
          &larr;&emsp;Back
        </Button>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
